var stickyNav = function() {
	var ScrollY = window.pageYOffset;
	var NavY = 141;

	if (ScrollY > NavY) {
		document.getElementsByClassName('header-wrapper')[0].classList.add('sticky');

	} else {
		document.getElementsByClassName('header-wrapper')[0].classList.remove('sticky');
	}
};

window.onscroll = function() { stickyNav() };

// ### Gallery

var galleryCounter = 0;
var galleryContainer = document.getElementById('gallery__image') || 0;

var galleryPaths = [
	"img/gallery/IMG_6440.jpg",//h
	// "img/gallery/IMG_6445.jpg",//v
	"img/gallery/IMG_6453.jpg",//h
	// "img/gallery/IMG_6471.jpg",//v
	"img/gallery/IMG_6477.jpg",//h
	// "img/gallery/IMG_6484.jpg",//v
	"img/gallery/IMG_6491.jpg"// h
];

function galleryChanger() {
	var len = galleryPaths.length;
	galleryCounter = galleryCounter + 1 === len ? 0 : galleryCounter + 1;
	// console.log(len, galleryCounter);/

	fadeInOut(galleryContainer, "out", 2000);
}

if (galleryContainer !== 0)	{
	window.setTimeout(function() {
		galleryContainer.src = galleryPaths[0];
		window.setInterval(galleryChanger, 6000);
	}, 1)
}

function fadeInOut(el, inOut, speed) {
	var opacity = inOut === "in" ? 0 : 1;
	var plusMinus = inOut === "in" ? 1 : (-1);

	el.style.opacity = opacity;

	var last = +new Date();
	var tick = function() {
		el.style.opacity = +el.style.opacity + plusMinus * ((new Date() - last) / speed);
		last = +new Date();

		if (+el.style.opacity < 1 && inOut === "in" || +el.style.opacity > 0 && inOut === "out") {
			(window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 1/speed);
		}
	};

	tick();

	if (inOut === "out") {
		window.setTimeout(function() {
			// var imageProportion = 1999 / 1333; //1,5
			// var imageProportion = galleryCounter % 2 === 0 ? 1999 / 1333 : 1333 / 1999;
			// console.log(imageProportion);

			// var width = getElementsWidth(document.querySelector('.biography'));
			var height = getElementsHeight(document.querySelector('.biography'));
			// console.log(height);
			// var windowProportion = width / height;
			// var windowOrientation = windowProportion > 1 ? 'horizontal' : 'vertical';
			// var imageOrientation = galleryCounter % 2 === 0 ? 'horizontal' : 'vertical';

			// console.log(windowOrientation, imageOrientation, width, height);

			// width = width + 'px';
			// height = height + 'px';

			if (window.innerHeight > 750) {
				document.querySelector('.gallery__wrapper').style.height = height;
			}

			// if (windowOrientation === 'horizontal') {
			// 	if (imageOrientation === 'horizontal') {
			// 		galleryContainer.style.width = 'auto';galleryContainer.style.height = height;
			// 	} else {
			// 		galleryContainer.style.height = 'auto';galleryContainer.style.width = width;
			// 	}
			// } else {
			// 	if (imageOrientation === 'horizontal') {
			// 		galleryContainer.style.height = height;
			// 		galleryContainer.style.width = 'auto';
			// 	} else {
			// 		galleryContainer.style.height = 'auto';galleryContainer.style.width = width;
			// 	}
			// }



			// document.querySelector('.gallery__wrapper').style.height = height;
			// console.log('offset: ' + height);
			// var vertical = galleryCounter % 2 === 0 ? false : true;
			// // galleryContainer.style.height = height;
			// if (vertical) {
			// 	// galleryContainer.style.width = 'auto';
			// 	// galleryContainer.style.height = height + 'px';
			// 	// galleryContainer.style.height = 'auto';galleryContainer.style.width = width;
			// 	galleryContainer.classList.add('vertical');
			// 	galleryContainer.classList.remove('horizontal');
			// } else {
			// 	// galleryContainer.style.width = '600px';
			// 	// galleryContainer.style.height = 'auto';
			// 	// galleryContainer.style.width = 'auto';
			// 	// galleryContainer.style.height = height;
			// 	galleryContainer.classList.add('horizontal');
			// 	galleryContainer.classList.remove('vertical');
			// }
			galleryContainer.src = galleryPaths[galleryCounter];
			fadeInOut(galleryContainer, "in", speed);
		}, speed);
	}
}

window.addEventListener('resize', function() {

});

function getElementsHeight(element) {
	return window.getComputedStyle(element).getPropertyValue('height');// + 'px';
}

function getElementsWidth(element) {
	return element.offsetWidth;// + 'px';
}

var loader = document.querySelector('.loading') || 0;
var eks = document.querySelector('.x') || 0;
var menu = document.querySelector('.menu') || 0;
var navContainer = document.querySelector('.nav-container');

if (loader) {
	document.querySelector('.loading').onload = function() {
		this.classList.remove('loading');
		document.querySelector('.loader').remove();
	};
}

if (eks) {
	eks.addEventListener('click', function() {
		navContainer.style.display = 'none';
		menu.classList.remove('mode-off');
		navContainer.classList.remove('mode-on');
	});
}

if (menu) {
	menu.addEventListener('click', function() {
		eks.style.display = 'block';
		navContainer.style.display = 'flex';
		menu.classList.add('mode-off');
		navContainer.classList.add('mode-on');
	});
}

window.onresize = function() {
	var width = window.innerWidth;
	if (width > 777) {
		navContainer.style.display = 'flex';
		menu.classList.add('mode-off');
		eks.classList.add('mode-off');
		navContainer.classList.remove('mode-on');
	} else {
		if (!navContainer.classList.contains('mode-on')) {
			navContainer.style.display = 'none';
			menu.classList.remove('mode-off');
			eks.classList.remove('mode-off');
		}
	}
}

var customers = document.querySelector('.opinion-wrapper') || 0;
if (customers) {
	var left = -1;
	var i = 1;
	setInterval(function() {
		var step = document.querySelector('.opinion').offsetWidth;
		customers.style.left = left + 'px';
		i = i === 7 ? 0 : i + 1;
		left = - i * step - 1;
		// console.log(left, step);
	}, 8000);
}

var rodox = document.querySelector('.rodo-x') || 0;
if (rodox) {
	rodox.addEventListener('click', function() {
		document.querySelector('.rodo-info').style.display = 'none';
	});
}

var rodo = document.querySelector('.rodo') || 0;
if (rodo) {
	rodo.addEventListener('click', function() {
		document.querySelector('.rodo-info').style.display = 'block';
	});
}

// forms

var submit = document.querySelector('#submit') || 0;

if (submit) {
	submit.addEventListener('click', function(e) {
		e.preventDefault();
		var ok = true;
		var infobox = '';
		var infoParagraph = document.querySelector('#info');

		var name = document.querySelector('#name').value;
		var email = document.querySelector('#email').value;
		var phone = document.querySelector('#phone-number').value;
		var topic = document.querySelector('#topic').value;
		var text = document.querySelector('#text').value;

		var formData = new FormData();
		formData.append('name', name);
		formData.append('email', email);
		formData.append('phone', phone);
		formData.append('topic', topic);
		formData.append('text', text);

		if(!name) {
			ok = false;
			infobox += 'Brak danych klienta! ';
		}

		if (!email && !phone) {
			ok = false;
			infobox += 'Brak danych kontaktowych! ';
		} else {
			var mailReg = new RegExp('^[0-9a-zA-Z_.-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,}$', 'gi');
			if (email && !mailReg.test(email)) {
				ok = false;
				infobox += 'Niepoprawny e-mail! ';
			}
		}

		if (!text) {
			ok = false;
			infobox += 'Nie wpisano treści! ';
		}

		if (ok) {
			var xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function() {
				if (this.readyState === 4 && this.status === 200) {
					infoParagraph.innerHTML = JSON.parse(this.responseText).infobox;
				}
			}
			xhr.open("POST", "form.php", true);
			xhr.send(formData);
		} else {
			infoParagraph.innerHTML = infobox;
		}
	});
}

// news

var newsImage = document.querySelectorAll('.blog .image-wrapper img') || 0;
if (newsImage) {
	let wrapper = document.querySelector('.image-wrapper');
	equalizeImgs(newsImage, wrapper);

	window.onresize = function() {
		equalizeImgs(newsImage, wrapper);
	}
}

function equalizeImgs(images, wrapper) {
	let wrapperWidth = wrapper.offsetWidth;
	let wrapperHeight = wrapper.offsetHeight;

	images.forEach(element => {
		if (element.naturalWidth / element.naturalHeight > wrapperWidth / wrapperHeight) {
			element.style.height = wrapperHeight + 'px';
			element.style.width = 'auto';
		} else {
			element.style.width = wrapperWidth + 'px';
			element.style.height = 'auto';
		}
	});
}

// christmas

var closeChristmas = document.getElementById('close-christmas') || 0;
if (closeChristmas) {
	closeChristmas.addEventListener('click', function() {
		document.querySelector('.christmas').style.display = 'none';
	});
}

// tags

var tagsContainer = document.querySelector('#tags-store') || 0;
if (tagsContainer) {
	refreshTagList(tagsContainer);
}

function refreshTagList(tagsCont) {
	const tags = tagsCont.innerHTML;
	const array = tags ? tags.split(',') : [];

	const listOfTags = document.querySelector('.list-of-tags');
	listOfTags.innerHTML = '';

	array.forEach(tag => {
		const listElement = document.createElement('div');
		const a = document.createElement('a');
		const text = document.createTextNode(tag);

		a.setAttribute('href', 'tag/' + tag)
		listElement.appendChild(a);
		a.appendChild(text);

		listOfTags.appendChild(listElement);

	});
}
